import Wrapper from '../components/Wrapper'

function NotFound() {
    return (
        <Wrapper>
            <div style={{"margin": "0 auto","width": "fit-content"}}>
            <svg style={{"marginTop": "100px"}} xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
            <b >Page Not Found</b>
            </div>
        </Wrapper>
    )
}

export default NotFound